import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import classnames from 'classnames';
import getQuestion from '../../functions/getQuestion';
import filterByAge from '../../functions/filterByAge';
import checkBifurcation from '../../functions/checkBifurcation';
import massageData from '../../functions/massageData';
import compileDataset from '../../functions/compileDataset';
import FirstTab from './FirstTab';
import CrossTab from './CrossTab';
import massageMatrixData from "../../functions/massageMatrixData";
import FirstTabMatrix from "./FirstTabMatrix";

const NoWordcloud = () => {
  return (
    <div className="no-wordcloud">
      <p>
        Eine Wordcloud kann derzeit nur unter dem Reiter{' '}
        <q>Übersicht</q> angezeigt werden.
      </p>
    </div>
  );
};

const Tabs = ({
  pollData,
  questionId,
  socioQuestionId,
  groupQuestionId,
  onSocioSelect,
  onGroupQuestionSelect,
  ageRange,
  onAgeSelect,
  activeTab,
  onTabChange,
  customerId
}) => {
  const {poll} = pollData;

  const question = getQuestion(poll.questions, questionId);
  let submissions = filterByAge(pollData.submissions, ...ageRange);
  submissions = checkBifurcation(question, pollData, submissions);

  // default tabs
  const tabs = [{
    id: '1',
    title: 'Übersicht',
    content: null
  }, {
    id: '2',
    title: 'Soziodemographische Kreuzanalyse',
    content: null
  }];

  if (activeTab === '1') {
    if(question.type === "matrix") {
      const dataSet = massageMatrixData(submissions, question);
      tabs[0].content = <FirstTabMatrix {...{dataSet, ageRange, onAgeSelect}}/>;
    } else {
      const dataSet = massageData(submissions, question);
      tabs[0].content = <FirstTab {...{dataSet, ageRange, onAgeSelect}}/>;
    }
  } else if (activeTab === '2') {
    const groupQuestion = getQuestion(poll.questions, socioQuestionId);
    const dataSet = compileDataset(
      submissions,
      question,
      groupQuestion
    );
    tabs[1].content = question.type === 'freetext' ? <NoWordcloud/> : (
      <CrossTab {...{
        dataSet,
        question,
        ageRange,
        groupQuestion,
        onAgeSelect,
        onSocioSelect
      }}/>
    );
  }

  // add cross analysis tab
  const {crossAnalysis = {}} = pollData.poll;
  const groupQuestions = crossAnalysis[customerId] || null;

  if (groupQuestions) {
    const tab = {
      id: '3',
      title: 'Spezialanalyse',
      content: null
    };
    if (activeTab === '3') {
      // determine active grouping question
      const def = groupQuestionId ? groupQuestions.find(
        q => q.question === groupQuestionId
      ) : groupQuestions[0];
      // const groupQuestionId = def.question;
      const groupQuestion = def.poll === poll._id ? getQuestion(
        poll.questions, def.question
      ) : {
        _id: def.question,
        text: def.label,
        type: def.type || 'single',
        answeroptions: def.answeroptions
      };
      const dataSet = compileDataset(
        submissions,
        question,
        groupQuestion
      );
      const groupOpts = groupQuestions.map(q => {
        let opt;
        if (q.poll === poll._id) {
          const question = getQuestion(poll.questions, q.question);
          opt = {
            value: question._id,
            label: question.shortText || question.text
          };
        } else {
          opt = {
            value: q.question,
            label: q.label || ''
          };
        }
        opt.label = opt.label.replace(/<br>.*$/, '');
        return opt;
      });
      tab.content = question.type === 'freetext' ? <NoWordcloud/> : (
        <CrossTab {...{
          dataSet,
          question,
          ageRange,
          groupQuestion,
          groupOpts,
          onGroupQuestionSelect,
          onAgeSelect
        }}/>
      );
    }
    tabs.push(tab);
  }

  return (
    <div className="animated fadeIn">
      <Row>
        <Col>
          <Nav tabs color="secondary">
            {tabs.map(tab => (
              <NavItem key={tab.id}>
                <NavLink
                  className={classnames({active: activeTab === tab.id})}
                  onClick={() => onTabChange(tab.id)}
                >
                  {tab.title}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab}>
            {tabs.map(tab => (
              <TabPane key={tab.id} tabId={tab.id}>
                {tab.content}
              </TabPane>
            ))}
          </TabContent>
        </Col>
      </Row>
    </div>
  );
};

Tabs.propTypes = {
  pollData: PropTypes.object.isRequired,
  questionId: PropTypes.string.isRequired,
  ageRange: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
  socioQuestionId: PropTypes.string.isRequired,
  groupQuestionId: PropTypes.string,
  onAgeSelect: PropTypes.func.isRequired,
  onSocioSelect: PropTypes.func.isRequired,
  onGroupQuestionSelect: PropTypes.func.isRequired,
  onTabChange: PropTypes.func.isRequired
};

Tabs.defaultProps = {
  groupQuestionId: null
};

export default Tabs;
